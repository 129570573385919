import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footer(){
    return (
        <Container fluid style={{backgroundColor:'whitesmoke'}}>
            <Container>
                <Row style={{paddingTop:40, paddingBottom:40}}>
                    <Col md={4} className='footer-box'>
                        {/* by WallOf Digitized Innovation (Pty) Ltd. - 2023 */}
                    </Col>
                    <Col md={4} className='footer-box'>
                        <b>Contact</b>
                        <label>Email: <a href='mailto:develop@thejumpoffcode.com'>develop@thejumpoffcode.com</a></label>
                        <label>Phone: <a href='tel:+27837651652'>083 765 1652</a></label>
                        
                        <label>WhatsApp: +27 (0) 83 765 1652</label>
                    </Col>
                    <Col md={4} className='footer-box'>
                        <b>Company</b>
                        trading as WallOf Digitized Innovation (Pty) Ltd. &copy; 2023
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
import logo from './logo.svg';
import './App.css';
import {Container, Navbar, Col, Row} from 'react-bootstrap'
import {} from 'react-dom'
import cloudLogo from './assets/img/cloud-server.png'

import deliveryIcon from './assets/img/delivery.png'
import deliveryFocusIcon from './assets/img/delivery-focus.png'

import walletIcon from './assets/img/wallet.png'
import walletFocusIcon from './assets/img/wallet-focus.png'

import calendarIcon from './assets/img/calendar.png'
import calendarFocusIcon from './assets/img/calendar-focus.png'
import leagueIcon from './assets/img/league.png'
import apiLogo from './assets/img/api.png'
import azureLogo from './assets/img/azure.png'
import gcloud from './assets/img/gcloud2.png'

import ContactUsModal from './component/ContactUsPage';
import Information from './component/Information';

function App() {
  return (
    <div className="App">
      <Information/>
      <Container style={{display:'flex',minHeight:'75vh',justifyContent:'center'}}>
        <div style={{backgroundColor:'transparent',justifyContent:'center', textAlign:'center',
        flexFlow:'column', display:'flex', alignContent:'center'}}>
          <img src={cloudLogo} height={200} width={200} style={{margin:'0 auto'}}/>
          <h1 style={{backgroundColor:'transparent',maxWidth:600}}>We build <b>Cloud-based </b> 
          <span className='fun-heading'><img src={gcloud}/> <img src={azureLogo}/></span>
          <br/><span className='fun-heading'>//systems, platforms</span> <br/> and <span className='fun-heading'>apps(<img src='https://cdn3.iconfinder.com/data/icons/social-media-logos-glyph/2048/5315_-_Apple-512.png'/> 
          &#183; 
           <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Android_logo_2019_%28stacked%29.svg/2346px-Android_logo_2019_%28stacked%29.svg.png'/>)</span></h1>
          <label>- write the solution, then you keep the code.. within your budget!</label>
          <ContactUsModal title='I need a system/app'/>
        </div>
      </Container>
      <Container style={{textAlign:'center', paddingTop:50, marginBottom:50}}>
        <h1>Our <strong>Expertise</strong></h1>
        <Row style={{gap:10,display:'flex',justifyContent:'center'}}>
          <Col md={3} sm={12} className='expertise-card'>
            <img src={walletFocusIcon}/>
            <h2>Payments <b>& Payouts</b></h2>
            <label>Integrate payments & payout systems into your e-Commerce website and mobile app.</label>
            <b>- Or -</b>
            <label>Build a platform and integrate an automated payout system powered by Cloud computing.</label>
          </Col>
          <Col md={3} sm={12} className='expertise-card'>
            <img src={deliveryFocusIcon}/>
            <h2>Logistics <b>& tracking</b></h2>
            <label>Build a platform for delivering. We integrate Google Maps API into the system.</label>
            <label>This is all done while we implement a custom-written SMS system to notify Customers.</label>
          </Col>
          <Col md={3} sm={12} className='expertise-card'>
            <img src={calendarFocusIcon}/>
            <h2>Events <b>& eTickets</b></h2>
            <label>Ticketing systems require a platform. We design a platform for both the event organisers and buyers of event tickets</label>
            <label>Also provide Android and iOS application for scanning and authorizing tickets.</label>
          </Col>
          <Col md={3} sm={12} className='expertise-card'>
            <img src={apiLogo}/>
            <h2>API & micro-services</h2>
            <label>Application Programming Interface.</label>
            <label>Break down the current backend and provide more flexibility integration practices like Mirco-services and Cloud functions.</label>
          </Col>
          <Col md={3} sm={12} className='expertise-card'>
            <img src={'https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/834dc645-6a97-47cb-919f-757125c3d244.png'}/>
            <h2>Monetization tools</h2>
            <label>Google AdSense, Google AdMob, subscription-based products and One-time purchases.</label>
            <label>We help your company generate revenue by implementing Google products to maximise your company revenue.</label>
          </Col>
          <Col md={12} sm={12} style={{marginTop:50}}>
            <label>*All systems are developed on <b>Google Cloud Platform</b>, <b>Azure</b> or <b>AWS</b>.</label>
          </Col>
        </Row>
        <ContactUsModal title='I need a system'/>
      </Container>
    </div>
  );
}

export default App;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, addDoc, collection} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKul_JxtZUIwmC2jI2O8ek61SoNeY20vk",
  authDomain: "webclients-32032.firebaseapp.com",
  projectId: "webclients-32032",
  storageBucket: "webclients-32032.appspot.com",
  messagingSenderId: "586201140266",
  appId: "1:586201140266:web:2b73952d3269b36a8e8753",
  measurementId: "G-Y0FH3T91VM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

export async function requestQuote(name, email, service, phone){
    const results = {}
    await addDoc(collection(firestore, "leads"), {
        name: name,
        email: email,
        service: service,
        phone: phone
    }).then((value)=>{
        results['stuff'] = value.id
        results['success'] = true;
    }).catch((e)=>{
        results['error'] = e
        results['success'] = false;
    })

    return results;
}